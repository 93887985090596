<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\modal-schedule-appointment.vue
  FILE_NAME : model-schedule-appointment
  AUTHOR : bykim
  DATE : 2024-01-18
  DESCRIPTION : 근무스케줄 스케쥴 등록 팝업
-->
<template>
	<div>
		<DxPopup
			:ref="'scheduleAppointmentPopup'"
			:title="schedulePopup.title"
			:visible="isOpen"
			:show-title="true"
			:min-width="schedulePopup.minWidth"
			:width="schedulePopup.width"
			:min-height="schedulePopup.minHeight"
			:height="schedulePopup.height"
			:resize-enabled="false"
			:drag-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			@hiding="closeModal"
			:content-template="schedulePopup.popupTemplate"
		>
			<template #modalPageInitialSettings="{}">
				<ModalPageInitialSettings
					ref="pageInitialSettings"
					:key="componentKey[0]"
					:appointment-data="appointmentData"
					@pageNext="onModalPageNext"
					@pagePrev="onModalPagePrev"
					@pageClose="onModalPageClose"
				/>
			</template>
			<template #modalPageSelectAgent="{}">
				<ModalPageSelectAgent
					ref="pageSelectAgent"
					:key="componentKey[1]"
					:appointment-data="appointmentData"
					@pageNext="onModalPageNext"
					@pagePrev="onModalPagePrev"
					@pageClose="onModalPageClose"
				/>
			</template>
      <template #modalPageWorktype="{}">
				<ModalPageWorkType
					ref="pageWorkType"
					:key="componentKey[2]"
					:appointment-data="appointmentData"
					@save="onSave"
					@pagePrev="onModalPagePrev"
					@pageClose="onModalPageClose"
				/>
			</template>
		</DxPopup>
	</div>
</template>

<script>
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxButton } from 'devextreme-vue/button';
import ModalPageWorkType from '@/pages/ewm/hr/work/schedule/popup/modal-page-worktype.vue';
import ModalPageInitialSettings from '@/pages/ewm/hr/work/schedule/popup/modal-page-initial-settings.vue';

import { camelCase } from 'eslint-plugin-vue/lib/utils/casing';
import ModalPageSelectAgent from '@/pages/ewm/hr/work/schedule/popup/modal-page-select-agent.vue';

export default {
	components: {
		ModalPageSelectAgent,
		ModalPageInitialSettings,
		ModalPageWorkType,
		DxButton,
		DxRequiredRule,
		DxValidator,
		DxSelectBox,
		DxToolbarItem,
		DxPopup,
		DxDateBox,
	},
	props: {
		isOpen: {
			default: false,
			type: Boolean,
		},
		appointmentData: {
			default: () => ({
				default: () => ({
					deptCdList: [], // 부서 리스트
					agtIdList: [], // 근무자 리스트
					startDate: null, // 시작일
					endDate: null, // 종료일
					pageName: '', // 현재 페이지
					nextPageName: '', // 다음 페이지
					prevPageName: '', // 이전 페이지
					agentData: {}, // 근무자
					workCategory: {}, // 근무구분
					submitList: [], // 저장할 데이터
				}),
			}),
		},
	},
	data() {
		return {
			componentKey: [0, 0, 0],
			schedulePopup: {
				title: '근무스케줄 등록',
				minWidth: 400,
				width: 850,
				minHeight: 300,
				height: 700,
				popupTemplate: 'initialSettingsTemplate',
			},
		};
	},
	watch: {
		isOpen() {
			this.setPopupProp();
		},
	},
	computed: {},
	methods: {
		/**
		 * 강제 재렌더링
		 */
		forceRerender() {
			switch (this.schedulePopup.popupTemplate) {
				case 'modalPageInitialSettings':
					this.componentKey[0] += 1;
					break;
				case 'modalPageSelectAgent':
					this.componentKey[1] += 1;
					break;
				case 'modalPageWorktype':
					this.componentKey[2] += 1;
					break;
				default:
					break;
			}
		},
		/**
		 * 팝업 템플릿 설정
		 */
		setPopupProp() {
			if (this.appointmentData.pageName === undefined || this.appointmentData.pageName === '') return;

			// 팝업 템플릿 설정
			this.schedulePopup.popupTemplate = camelCase(this.appointmentData.pageName);
			this.forceRerender();

			// 팝업 타이틀 설정
			if (this.appointmentData?.workCategory?.value === this.$_enums.ewm.workCategory.BASIC.value) {
				this.schedulePopup.title = '일반근무';
			} else {
				this.schedulePopup.title = '시간외근무';
			}

			// 팝업 타이틀 종류 설정 (등록/변경)
			if (this.appointmentData?.agentData) {
				this.schedulePopup.title += ' 변경';
			} else {
				this.schedulePopup.title += ' 등록';
			}
		},
		/**
		 * 팝업 닫기
		 */
		closeModal() {
			this.$emit('closeModal');
		},
		/**
		 * 저장
		 * @param e
		 */
		onSave(e) {
			this.$emit('saveModal', e);
		},
		/**
		 * 모달 페이지 이동
		 * @param e
		 */
		onModalPageNext(e) {
			this.appointmentData = e;
			if (this.appointmentData.nextPageName === undefined || this.appointmentData.nextPageName === '') return;
			this.schedulePopup.popupTemplate = camelCase(this.appointmentData.nextPageName);
		},
		/**
		 * 모달 페이지 이전
		 * @param e
		 */
		onModalPagePrev(e) {
			this.appointmentData = e;
			if (this.appointmentData.prevPageName === undefined || this.appointmentData.prevPageName === '') return;
			this.schedulePopup.popupTemplate = camelCase(this.appointmentData.prevPageName);
		},
		/**
		 * 모달 페이지 닫기
		 */
		onModalPageClose() {
			this.$refs['scheduleAppointmentPopup'].instance.hide();
		},
	},
	created() {},
	mounted() {},
};
</script>

<style lang="scss" scoped></style>
